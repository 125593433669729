body,
#root * {
  font-family: Helvetica, Verdana, sans-serif;
}
body::-webkit-scrollbar {
  width: 1em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
#root .ant-checkbox-wrapper {
  font-size: 13px;
}
.multi-select label span {
  width: 80%;
  float: left;
  color: #000000;
}

div.leaflet-print .leaflet-google-mutant.leaflet-top.leaflet-left {
  width: 100% !important;
  height: 100% !important;
}

.map-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.leaflet-container {
  height: 100%;
}

#root {
  height: 100%;
}

#legend {
}
#legend ul {
  padding: 0;
  margin: 15px 0 0 0;
}
#legend li {
  margin: 20px 0 0 0;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  position: relative;
  cursor: pointer;
}
#legend li.site-area {
  cursor: auto;
}
#legend li.off {
  opacity: 0.7;
}
#legend li.off span,
#legend li.off .spot {
  opacity: 0.3;
}
#legend li:first-child {
  margin: 0;
}
#legend li > span {
  width: 30px;
  float: left;
  display: inline-block;
  text-align: center;
}
#legend h3 {
  font-size: 18px;
  text-decoration: underline;
}
#legend li.site-area span {
  width: 16px;
  height: 16px;
  float: left;
  margin: 0 7px;
  display: inline-block;
  border: 1px solid #343434;
  background: rgba(1, 115, 180, 0.4);
}
#filter {
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 999;
}
.leaflet-control-attribution {
  display: none;
}

#root .leaflet-popup-close-button {
  color: rgba(0, 0, 0, 0.2);
}
#root .leaflet-popup-close-button:hover {
  color: rgba(0, 0, 0, 0.4);
}
.popup td span {
  font-weight: normal;
}
.popup h4 {
  font-size: 16px;
  padding-right: 15px;
}
.popup hr {
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.2);
}
.amplify-section-footer span div:last-child {
  visibility: hidden;
}
.unauthorised {
  padding: 10px;
}

.company_site_filter {
  box-shadow: inset 0px 9px 22px -8px rgba(0, 0, 0, 0.35);
  background-color: rgba(204, 204, 204, 0.42);
}

.ant-select {
  width: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  @page {
    margin: 5mm;
  }

  #dpi {
    visibility: hidden;
  }

  .print-data-row {
    page-break-inside: avoid;
  }

  .ant-modal-content,
  .ant-modal-wrap,
  .print-paper-preview,
  .ant-modal {
    position: static !important;
    overflow: visible;
  }
  * {
    visibility: hidden;
  }
  #print,
  #print * {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .printing.data {
    height: auto !important;
    overflow: visible !important;
  }
}
#print-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}
#print {
  position: absolute;
  opacity: 0;
}
.qr {
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 99999999;
  background: #fff;
}
.print-options {
  float: left;
  width: 250px;
  box-sizing: border-box;
  padding-left: 20px;
}
.print-options h4 {
  font-size: 16px;
  padding: 0 0 1px 0;
  display: inline-block;
  clear: both;
  color: #666;
  border-bottom: 1px dotted #ccc;
}
.print-options small {
  display: block;
  font-size: 12px;
}
.form-row {
  position: relative;
  width: 100%;
  margin: 30px 0 0 0;
}
.form-row label {
  font-size: 14px;
  font-weight: bold;
}
#toolbar {
  top: 30px;
  right: 30px;
  position: absolute;
  z-index: 998;
}

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1);
    fill: #0173b4;
  }
  30% {
    transform: scale(1.08);
    fill: yellow;
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
    fill: #0173b4;
  }
  90% {
    transform: scale(1.04);
    fill: yellow;
  }
  100% {
    transform: scale(1);
    fill: #0173b4;
  }
}

.fresh-polygon {
  display: inline-block;
  fill: #0173b4;
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin: center;
}
.stale-polygon {
  /* opacity: 0; */
  /* transition: 1s; */
  fill: #0173b4;
  transform-origin: center;
}

/*
 * Uses border rather than bg-color aas a filthy hack to make IE/Edge behave themselves
*/
#print-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  z-index: 999999999;
  height: 64px;
  height: 0;
  border-top: 64px solid #fff;
  vertical-align: middle;
  box-sizing: border-box;
}
#print-header > div {
  position: absolute;
  width: 100%;
  height: 64px;
  top: -64px;
  left: 0;
}
#print-header img {
  max-height: 48px;
  max-width: 25%;
  float: left;
  margin-right: 20px;
}
#print-header ul {
  margin: 0;
  float: right;
  padding: 0;
}
#print-header li {
  list-style: none;
  display: block;
  margin: 0 30px 0 0;
}
#print-data {
  margin: 10mm 0 0 0;
  padding: 64px 0 0 0;
}
.print-data-row > div {
  padding: 15px;
  border: 1px solid #ccc;
  overflow: visible;
  position: relative;
}
.print-data-row {
  width: 90%;
  margin: 15px auto 0 auto;
  padding: 10px 0 0 0;
}
.pushed-right {
  float: right;
  text-align: right;
}
.print-data-row h3 {
  border-bottom: 1px dotted #eee;
  font-weight: bold;
}
.print-data-row img {
  position: absolute;
  height: 30px;
  top: -10px;
  left: -10px;
}
.print-data-row .row-content {
  overflow: hidden;
}
.print-data-row .location {
  float: right;
  text-align: left;
  width: 250px;
}
.print-data-row .info-points {
  float: left;
  margin: 0;
  padding: 0;
}
.info-points li {
  list-style: none;
  margin-left: 0;
}
.spot {
  z-index: 1999999998 !important;
}
.spot.cluster {
  z-index: 2099999999 !important;
}
.spot div {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.spot.cluster div {
  color: #fff;
  text-align: center;
  line-height: 20px;
}
.spot.blackspot div {
  margin: -10px 0 0 -10px;
  background: #555; /* fallback for old browsers */
  animation: blinkBlack 0.5s infinite;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px,
    inset rgba(0, 0, 0, 0.3) 0 -1px 9px, rgba(0, 0, 0, 0.5) 0 2px 12px;
  background: linear-gradient(to right, #555, #444);
}
@keyframes blinkBlack {
  from {
    background-color: #555;
  }
  50% {
    background-color: #333;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #000 0 -1px 9px,
      rgba(0, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #555;
  }
}
#legend div.spot div {
  animation: none;
  margin: 2px 15px 0 5px;
}
li .spot div {
  width: 15px;
  height: 15px;
}
li .spot {
  float: left;
}
.spot.hotspot div {
  z-index: 998;
  background: #ff416c; /* fallback for old browsers */
  animation: blinkRed 0.5s infinite;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px,
    inset rgba(0, 0, 0, 0.3) 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}
@keyframes blinkRed {
  from {
    background-color: #f00;
  }
  50% {
    background-color: #a00;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
      rgba(255, 0, 0, 0.5) 0 2px 0;
  }
  to {
    background-color: #f00;
  }
}
#wrapper .hotspot .leaflet-popup-content-wrapper {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}
#wrapper .blackspot .leaflet-popup-content-wrapper {
  background: linear-gradient(to right, #555, #444);
}
.spot-popup li {
  color: #fff;
}
.spot-popup .score {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  display: block;
  background: rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  line-height: 24px;
  border-radius: 3px;
  margin: 0 0 10px 0;
  overflow: hidden;
}
.spot-popup ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.spot-popup li {
  margin: 0 0 10px 0;
}
.spot-popup pre {
  color: #fff;
}
.spot-popup .score span {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.spot-popup .score strong {
  text-transform: uppercase;
  padding: 0 10px;
}
#wrapper .hotspot .leaflet-popup-tip {
  background: #ff464b;
}
#wrapper .blackspot .leaflet-popup-tip {
  background: #454545;
}
/*
 * Start merged.css
*/
.leaflet-popup-content-wrapper {
  overflow: hidden;
}
.leaflet-bottom.leaflet-left {
  z-index: 400 !important;
}

/* Am using borders instead of background to fix printing on IE/Edge */
.compliant-marker-cluster {
  border: 20px solid rgba(96, 253, 33, 0.65);
  position: relative;
  border-radius: 20px;
}
.compliant-marker-cluster div {
  border: 14px solid rgba(42, 241, 42, 0.68);
  border-radius: 14px;
  position: absolute;
  top: -14px;
  left: -14px;
}
.compliant-marker-cluster span {
  position: absolute;
  top: -9px;
  left: -14px;
  display: inline-block;
  width: 28px;
  text-align: center;
  line-height: 20px;
  color: black;
}
.non-compliant-marker-cluster {
  border: 20px solid rgba(253, 21, 0, 0.6);
  position: relative;
  border-radius: 20px;
}
.non-compliant-marker-cluster div {
  border: 14px solid rgba(241, 4, 0, 0.6);
  border-radius: 14px;
  position: absolute;
  top: -14px;
  left: -14px;
}
.non-compliant-marker-cluster span {
  position: absolute;
  top: -9px;
  left: -14px;
  display: inline-block;
  width: 28px;
  text-align: center;
  line-height: 20px;
  color: white;
}
#legend .compliant-marker-cluster,
#legend .non-compliant-marker-cluster {
  border-width: 10px;
  border-radius: 10px;
  margin-left: 5px;
  width: 0;
  height: 0;
}
#legend .compliant-marker-cluster div,
#legend .non-compliant-marker-cluster div {
  border-width: 7px;
  top: -7px;
  left: -7px;
  border-radius: 7px;
}
#legend .compliant-marker-cluster span,
#legend .non-compliant-marker-cluster span {
  top: -5px;
  left: -7px;
  width: 14px;
  line-height: 10px;
  font-size: 10px;
}

.leaflet-marker-icon.compliant-marker-cluster {
  z-index: 200 !important;
}
.leaflet-marker-icon.non-compliant-marker-cluster {
  z-index: 999 !important;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  /*z-index: 997 !important;*/
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.leaflet-popup-content tr td:first-child {
  font-weight: bold;
  padding: 5px 10px 0 0;
  vertical-align: top;
  width: 25%;
  min-width: 125px;
}
.leaflet-popup-content td {
  padding-top: 5px !important;
  vertical-align: top;
}
div.leaflet-popup-content h4 {
  margin-bottom: 0;
  font-weight: bold;
  display: inline;
}
.leaflet-popup h4 a {
  color: white;
  padding: 4px;
  border-radius: 2px;
}
.leaflet-popup.pfie h4 a,
.leaflet-popup.pfier h4 a,
.leaflet-popup.pfief h4 a,
.leaflet-popup.pfierf h4 a{
  background: #b21515;
}
.leaflet-popup.pfize h4 a,
.leaflet-popup.pfizer h4 a{
  background: #14a848;
}
.leaflet-popup.pfie h4 a:hover,
.leaflet-popup.pfier h4 a:hover,
.leaflet-popup.pfief h4 a:hover,
.leaflet-popup.pfierf h4 a:hover{
  background: #590810;
}
.leaflet-popup.pfize h4 a:hover,
.leaflet-popup.pfizer h4 a:hover{
  background: #075416;
}
.leaflet-popup.non-compliant h4 a {
  background: #b21515;
}
.leaflet-popup.compliant h4 a {
  background: #14a848;
}
.leaflet-popup.non-compliant h4 a:hover {
  background: #590810;
}
.leaflet-popup.compliant h4 a:hover {
  background: #075416;
}
.leaflet-popup.pfize,
.leaflet-popup.pfizer{
  border-radius: 10px;
  background: -webkit-linear-gradient(
    to right,
    rgba(248, 255, 174, 1),
    rgba(67, 198, 172, 1)
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(248, 255, 174, 1),
    rgba(67, 198, 172, 1)
  ) !important;
}

.leaflet-popup.pfie,
.leaflet-popup.pfier,
.leaflet-popup.pfief,
.leaflet-popup.pfierf{
  border-radius: 10px;
  background: -webkit-linear-gradient(
    to right,
    rgba(147, 41, 30, 1),
    rgba(237, 33, 58, 1)
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(147, 41, 30, 1),
    rgba(237, 33, 58, 1)
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.leaflet-popup.pfize .leaflet-popup-content-wrapper,
.leaflet-popup.pfizer .leaflet-popup-content-wrapper,
.leaflet-popup.pfie .leaflet-popup-content-wrapper,
.leaflet-popup.pfier .leaflet-popup-content-wrapper,
.leaflet-popup.pfief .leaflet-popup-content-wrapper,
.leaflet-popup.pfierf .leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.3);
  background-image: repeating-linear-gradient(
    145deg,
    rgba(204, 204, 204, 0.5),
    rgba(204, 204, 204, 0.5) 30px,
    rgba(219, 219, 219, 0.5) 30px,
    rgba(219, 219, 219, 0.5) 60px
  );
}

.leaflet-popup.pfie .leaflet-popup-content-wrapper,
.leaflet-popup.pfier .leaflet-popup-content-wrapper,
.leaflet-popup.pfief .leaflet-popup-content-wrapper,
.leaflet-popup.pfierf .leaflet-popup-content-wrapper{
  background-color: transparent;
  color: #ffffff !important;
}
.leaflet-popup.pfize h4,
.leaflet-popup.pfizer h4{
  color: #fff;
}

span.verifiedBy span {
  font-weight: bold;
}

.leaflet-popup.compliant .leaflet-popup-content-wrapper {
  background: -webkit-linear-gradient(
    to right,
    rgba(248, 255, 174, 0.3),
    rgba(67, 198, 172, 0.3)
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(248, 255, 174, 0.31),
    rgba(67, 198, 172, 0.31)
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.leaflet-popup.non-compliant .leaflet-popup-content-wrapper {
  background: -webkit-linear-gradient(
    to right,
    rgba(147, 41, 30, 0.2),
    rgba(237, 33, 58, 0.2)
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgba(147, 41, 30, 0.2),
    rgba(237, 33, 58, 0.2)
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#root .leaflet-control-container .leaflet-top.leaflet-left {
  z-index: 99999999;
}
.leaflet-popup.leaflet-zoom-animated {
  background-color: white;
  border-radius: 10px;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 9999999;
}
.loading > div {
  background: white;
  display: inline-block;
  padding: 10px;
  border-radius: 0 0 3px 3px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.23);
}
.loading > div > span {
  padding-left: 15px;
}
.map-wrapper {
  /* placeholder */
}
.map-wrapper * {
  text-indent: none;
}

/*
 * GOTO
*/
#goto {
  top: 30px;
  right: 30px;
  position: absolute;
  z-index: 9999999;
}
#goto-content {
  width: 360px;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
}
#goto-content label {
  font-weight: bold;
}
#goto-content small {
  font-size: 11px;
}
#goto .goto-button.selected {
  padding-right: 30px;
}
#goto .close {
  margin-left: 5px;
  display: block;
  position: absolute;
  right: 10px;
  z-index: 9999999999;
  top: 10px;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .leaflet-control-zoom {
    display: none;
  }
}

#print-options .form-row > label {
  display: block;
}
#print-options .form-row {
  margin: 15px 0 0 0;
}
#print-options {
  width: 400px;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
}
#root .check-group {
  max-height: 300px;
  overflow-y: scroll;
}
#root .check-group label {
  display: block;
}

.check-group::-webkit-scrollbar {
  width: 1em;
}
.check-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.check-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* IE11 font fix */
.ant-calendar-picker-container {
  font-family: Helvetica, Verdana, sans-serif !important;
}

/*
 * iOS Fix
*/
#root .leaflet-pane.leaflet-map-pane {
  z-index: 99999;
}
body .ant-notification {
  z-index: 1000000000;
}

#depth-buttons {
  z-index: 9999999;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

@media (max-width: 768px) {
  #depth-buttons {
    right: auto;
    left: 30px;
  }

  .ant-calendar-range,
  .ant-calendar-picker-container {
    max-width: 100%;
  }
}

.fatality {
  outline-offset: 2px;
  outline: 3px dashed #ff0909 !important;
}

.capitalize {
  text-transform: capitalize;
}

.fatality-legend {
  margin-right: 5px;
}
