body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root{
  height:600px;
}
#map, .leaflet-container {
  height: 100%;
}

.amplify-nav-bar {
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-repeat: no-repeat;
  color: #fff;
}

.amplify-nav-bar:before {
  content: 'CLOSED BETA';
  padding-top: 25px;
  padding-left: 80px;
  left: 10px;
  display: block;
  width: auto;
  height: 100%;
  position: absolute;
  background-image: url("./Images/forwood-logo.png");
  background-size: auto 85%;
  background-position: 5px;
  background-repeat: no-repeat;
}
